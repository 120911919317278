import React from 'react'
import SimpleMap from './Map'
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import css from "../index.scss";



export default function Contact() {

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  }


  return (
    <div className='contact'>
        <div className='contact-header'>
          <div className='contact-header_text'>Contact</div>
          <div className='contact-header_line'></div>
        </div>
        <div className='contactcontent'>
          <div className='contact_contactcontent-address'>
            <div style={{fontWeight:'500',fontSize:'1.5em'}}>Follow our social media accounts or get in touch with us.</div>
            <a href="mailto:houfrobotic@gmail.com">houfrobotic@gmail.com</a>
            <div>+90 (501) 351 98 98</div>
            <div>Topçu Neighbourhood Street: 1501 Number: 13/A Elvankent <br/>Etimesgut ANKARA</div>
            <div className='contactcontent-social'>
              {/* <FaLinkedinIn style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://www.linkedin.com/company/gfds-inc/')}/> */}
              <FaYoutube style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://www.youtube.com/@houfrobotic')}/>
              <FaInstagram style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://www.instagram.com/houf.robotic/')}/>
              <FaFacebookF style={{color:css.colorBlue}} size={26} onClick={() => openInNewTab('https://www.facebook.com/houf.robotic')}/>
              {/* <FaXTwitter style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://twitter.com/gfds_tr')}/> */}
            </div>
          </div>
          <div className='splitter-vertical'></div>
          <SimpleMap lat={39.95436053092463} lng={32.616335356664436} /> 
        </div>
    </div>
  )
}
