import React, { useState } from 'react';

import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-thumbnail.scss';
import "lightgallery/css/lg-video.css";


import h1 from '../img/hseries/1.jpeg'
import h2 from '../img/hseries/2.jpeg'
import h3 from '../img/hseries/3.jpeg'

import o1 from '../img/oseries/1.jpeg'
import o2 from '../img/oseries/2.jpeg'
import o3 from '../img/oseries/3.jpeg'
import o4 from '../img/oseries/4.jpeg'
import o5 from '../img/oseries/5.jpeg'
import o6 from '../img/oseries/6.jpeg'

import u1 from '../img/useries/1.jpeg'
import u2 from '../img/useries/2.jpeg'
import u3 from '../img/useries/3.jpeg'
import u4 from '../img/useries/4.jpeg'
import u5 from '../img/useries/5.jpeg'
import u6 from '../img/useries/6.jpeg'
import u7 from '../img/useries/7.jpeg'
import u8 from '../img/useries/8.jpeg'
import u9 from '../img/useries/9.jpeg'

import f1 from '../img/fseries/1.jpeg'
import f2 from '../img/fseries/2.jpeg'
import f3 from '../img/fseries/3.jpeg'
import f6 from '../img/fseries/6.jpeg'
import f8 from '../img/fseries/8.jpeg'
import f9 from '../img/fseries/9.jpeg'


export default function Games() {
  const [activeTab, setActiveTab] = useState('hseries');

  const changeActiveTab = (e) => {
    const tabid = e.target.getAttribute('tabid');
    setActiveTab(tabid);
  }

  return (
    <div className='games'>
        <div className='games-header'>
            <div className='games-header_text'>Models</div>
            <div className='games-header_line'></div>
        </div>

        <div className='gamescontent'>

          <div className='gamescontent-tabs'>
            <div tabid="hseries" className={`gamescontent-tabs_button ${activeTab === 'hseries' ? 'activeTab' : ''}`} onClick={changeActiveTab}>H Series</div>
            <div tabid="oseries" className={`gamescontent-tabs_button ${activeTab === 'oseries' ? 'activeTab' : ''}`} onClick={changeActiveTab}>O Series</div>
            <div tabid="useries" className={`gamescontent-tabs_button ${activeTab === 'useries' ? 'activeTab' : ''}`} onClick={changeActiveTab}>U Series</div>
            <div tabid="fseries" className={`gamescontent-tabs_button ${activeTab === 'fseries' ? 'activeTab' : ''}`} onClick={changeActiveTab}>F Series</div>
          </div>

          <div className='gamescontent-game' style={activeTab==="hseries"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail, lgVideo]}>
              <a data-lg-size="2048-1625" data-lg-thumb={h1} href={h1}>
                <img src={h1} alt=""/>
              </a>
              <a data-lg-size="1943-1625" data-lg-thumb={h2} href={h2}>
                <img src={h2} alt=""/>
              </a>
              <a data-lg-size="2148-1625" data-lg-thumb={h3} href={h3}>
                <img src={h3} alt=""/>
              </a>
            </LightGallery>
          </div>

          <div className='gamescontent-animation' style={activeTab==="oseries"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail, lgVideo]}>
              <a data-lg-size="2304-1092" data-lg-thumb={o1} href={o1}>
                <img src={o1} alt=""/>
              </a>
              <a data-lg-size="2304-1092" data-lg-thumb={o2} href={o2}>
                <img src={o2} alt=""/>
              </a>
              <a data-lg-size="2304-1092" data-lg-thumb={o3} href={o3}>
                <img src={o3} alt=""/>
              </a>
              <a data-lg-size="2016-954" data-lg-thumb={o4} href={o4}>
                <img src={o4} alt=""/>
              </a>
              <a data-lg-size="2016-954" data-lg-thumb={o5} href={o5}>
                <img src={o5} alt=""/>
              </a>
              <a data-lg-size="2016-954" data-lg-thumb={o6} href={o6}>
                <img src={o6} alt=""/>
              </a>
            </LightGallery>
          </div>


          <div className='gamescontent-defence' style={activeTab==="useries"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail]}>
              <a data-lg-size="2016-1134" data-lg-thumb={u1} href={u1}>
                <img src={u1} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u2} href={u2}>
                <img src={u2} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u3} href={u3}>
                <img src={u3} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u4} href={u4}>
                <img src={u4} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u5} href={u5}>
                <img src={u5} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u6} href={u6}>
                <img src={u6} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u7} href={u7}>
                <img src={u7} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u8} href={u8}>
                <img src={u8} alt=""/>
              </a>
              <a data-lg-size="2016-1134" data-lg-thumb={u9} href={u9}>
                <img src={u9} alt=""/>
              </a>
            </LightGallery>
          </div>


          <div className='gamescontent-vr' style={activeTab==="fseries"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail, lgVideo]}>
              <a data-lg-size="2016-1512" data-lg-thumb={f1} href={f1}>
                <img src={f1} alt=""/>
              </a>
              <a data-lg-size="2016-1512" data-lg-thumb={f2} href={f2}>
                <img src={f2} alt=""/>
              </a>
              <a data-lg-size="2016-1512" data-lg-thumb={f3} href={f3}>
                <img src={f3} alt=""/>
              </a>


              <a data-lg-size="2016-1512" data-lg-thumb={f6} href={f6}>
                <img src={f6} alt=""/>
              </a>

              <a data-lg-size="2016-1512" data-lg-thumb={f8} href={f8}>
                <img src={f8} alt=""/>
              </a>
              <a data-lg-size="2016-1512" data-lg-thumb={f9} href={f9}>
                <img src={f9} alt=""/>
              </a>
            </LightGallery>
          </div>


        </div>
    </div>
  )
}
