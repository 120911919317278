import React from 'react'
import aboutimage from '../img/about.png'

export default function About() {
  return (
      <div className='about'>
          <div className='about-header'>
              <div className='about-header_text'>About</div>
              <div className='about-header_line'></div>
          </div>

          <div className='aboutcontent'>

            <div className='about-text'>
              <p>Established in 2020, Houf Robotic has become a key player in CNC router machine manufacturing. We offer high-quality desktop models for both amateurs and professionals.

Our H series machines prioritize precision and efficiency, while the O series boasts a user-friendly interface. The U series meets industrial needs with its power and durability, and the F series offers portability for amateurs.

Equipped with cutting-edge features, our CNC router machines cater to traditional crafts like woodworking and advanced applications such as PCB processing. Committed to innovation, we aim to maintain industry leadership through continuous research and development.

Join us at Houf Robotic to bring your creative projects to life.</p>

            </div>

            <div className='about-image'>
              <img src={aboutimage} alt="" />
            </div>

          </div>
      </div>
  )
}
