import React, { useState, useEffect } from 'react'
import logo from "../img/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import css from "../index.scss";


export default function Header() {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState("transparent")


  //When scroll goes down, header changes color
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const listenScrollEvent = (event) => {
    if (window.scrollY < window.innerHeight-120) {
      return setHeaderColor("transparent")
    } else {
      return setHeaderColor("color")
    } 
  }




  //Click to scroll

  const clickToScroll = (i) => {


    setIsMobileMenuOpen(false)

    //our offset value
    let offset = 120;

    if (window.innerWidth<1024) {
      offset = 69;
    }

    //to Intro
    let intro = document.getElementsByClassName("intro")[0];

    //to Upcoming
    let upcoming = document.getElementsByClassName("upcoming")[0];
    let upcomingPosition = upcoming.getBoundingClientRect().top;
    let upcomingFinalPosition = upcomingPosition + window.scrollY - offset;

    //to About
    let about = document.getElementsByClassName("about")[0];
    let aboutPosition = about.getBoundingClientRect().top;
    let aboutFinalPosition = aboutPosition + window.scrollY - offset;

    //to Games
    let games = document.getElementsByClassName("games")[0];
    let gamesPosition = games.getBoundingClientRect().top;
    let gamesFinalPosition = gamesPosition + window.scrollY - offset;

    //to Team
    // let team = document.getElementsByClassName("team")[0];
    // let teamPosition = team.getBoundingClientRect().top;
    // let teamFinalPosition = teamPosition + window.scrollY - offset;

    //to Careers
    // let careers = document.getElementsByClassName("careers")[0];
    let careers = document.getElementsByClassName("contact")[0];
    let careersPosition = careers.getBoundingClientRect().top;
    let careersFinalPosition = careersPosition + window.scrollY - offset;

    //to Contact
    let contact = document.getElementsByClassName("contact")[0];
    let contactPosition = contact.getBoundingClientRect().top;
    let contactFinalPosition = contactPosition + window.scrollY - offset;

    

    //execute
    if (i.target.innerHTML === "Newly Released") {
      window.scrollTo({top:upcomingFinalPosition,behavior:"smooth"});
    } else if (i.target.innerHTML === "About") {
      window.scrollTo({top:aboutFinalPosition,behavior:"smooth"});
    } else if (i.target.innerHTML === "Models") {
      window.scrollTo({top:gamesFinalPosition,behavior:"smooth"});
    // } else if (i.target.innerHTML === "Company &amp; Team") {
    //   window.scrollTo({top:teamFinalPosition,behavior:"smooth"});
    } else if (i.target.innerHTML === "Careers") {
      window.scrollTo({top:careersFinalPosition,behavior:"smooth"});
    } else if (i.target.innerHTML === "Contact") {
      window.scrollTo({top:contactFinalPosition,behavior:"smooth"});
    } else {
      intro.scrollIntoView({behavior:"smooth"});
    }
  }




  return (
    <>
      <div className='header' style={headerColor!=="transparent"?{backgroundColor: css.colorWhite, boxShadow:'0px 2px 13px 0px rgba(0,0,0,0.15)'}:null}>
          <div className='header-logo' onClick={clickToScroll}>
              <img src={logo} alt="" style={headerColor!=="transparent"?null:{filter:"brightness(0) invert(1)"}}/>
          </div>

          <div className='header-menu' style={headerColor!=="transparent"?{color: 'black'}:null}>
            <div className='header-menu_button' onClick={clickToScroll}>Newly Released</div>
            <div className='header-menu_button' onClick={clickToScroll}>About</div>
            <div className='header-menu_button' onClick={clickToScroll}>Models</div>
            {/* <div className='header-menu_button' onClick={clickToScroll}>Company & Team</div> */}
            {/* <div className='header-menu_button' onClick={clickToScroll}>Careers</div> */}
            <div className='header-menu_button' onClick={clickToScroll}>Contact</div>
          </div>

          <div className='header-hamburger_button' onClick={()=>setIsMobileMenuOpen(!isMobileMenuOpen)}>
            {isMobileMenuOpen?<IoMdClose size={44} style={headerColor!=="transparent"?null:{color:css.colorWhite}} />:<GiHamburgerMenu size={40} style={headerColor!=="transparent"?null:{color:css.colorWhite}} />}
            
            
          </div>
      </div>

      <div className='header-menumobile' style={isMobileMenuOpen?{display:'flex'}:{display:'none'}}>
        <div className='header-menu_mobilebutton' onClick={clickToScroll}>Newly Released</div>
        <div className='header-menu_mobilebutton' onClick={clickToScroll}>About</div>
        <div className='header-menu_mobilebutton' onClick={clickToScroll}>Models</div>
        {/* <div className='header-menu_mobilebutton' onClick={clickToScroll}>Company & Team</div> */}
        {/* <div className='header-menu_mobilebutton' onClick={clickToScroll}>Careers</div> */}
        <div className='header-menu_mobilebutton' onClick={clickToScroll}>Contact</div>
      </div>
    </>
  )
}
